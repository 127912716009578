@import 'reset.css';
@import 'variables.scss';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Reserva-Serif-Regular';
  line-height: 1.35;
  background-color: black;
  color: white;
  // overflow-y: scroll;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Reserva-Serif-Bold';
  src: url('../assets/fonts/ReservaSerif-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Serif-Light';
  src: url('../assets/fonts/ReservaSerif-Light.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Serif-Regular';
  src: url('../assets/fonts/ReservaSerif-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Serif-Medium';
  src: url('../assets/fonts/ReservaSerif-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Serif-Thin';
  src: url('../assets/fonts/ReservaSerif-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Light';
  src: url('../assets/fonts/ReservaSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Bold';
  src: url('../assets/fonts/ReservaSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Light';
  src: url('../assets/fonts/ReservaSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Regular';
  src: url('../assets/fonts/ReservaSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Medium';
  src: url('../assets/fonts/ReservaSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Thin';
  src: url('../assets/fonts/ReservaSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Reserva-Sans-Light';
  src: url('../assets/fonts/ReservaSans-Light.woff') format('woff');
}

*:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
  }
}

h1 {
  font-size: 24px;
  line-height: 1.35em;
  font-weight: 600;
  // color: $color-green;
}

h2 {
  font-size: 22px;
  line-height: 1.35em;
  font-weight: 500;
}

h3 {
  font-size: 15px;
  line-height: 1.35em;
}

h4 {
  font-size: 12px;
  line-height: 1.35em;
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
  // height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  // padding: 0 5%;
  // width: 90%;
  max-width: 1280px;
}

.glow {
  position: absolute;
  height: 100%;
  width: 100%;
  // background-color: #c4a968;
  background: radial-gradient(rgba(196, 169, 104, 0.2), rgba(0, 0, 0, 0.1) 70%);
  // background: red;
  // background-size: 800px 100%;
  // background-position: 50% 100%;
  // background-repeat: no-repeat;
  border-radius: 60%;
  z-index: 1;
}

@media (min-width: $screen-xl) {
  h1 {
    font-size: 36px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 13px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
