@import '../../../../styles/variables.scss';

.faq {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.faq__left {
  display: flex;
  flex-direction: column;
  width: 100%;
  header {
    font-family: 'Reserva-Serif-Bold';
    font-size: 30px;
    text-align: center;
  }

  div {
    font-family: 'Reserva-Sans-Thin';
    font-size: 12px;
    margin-top: 10px;
    text-align: center;

    span {
      font-family: 'Reserva-Sans-Bold';
    }
  }
}

.faq__right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faq__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0px;
  border-bottom: 1px solid #8f8f8f75;
  font-family: 'Reserva-Sans-Regular';
  font-size: 14px;
  cursor: pointer;

  svg {
    // cursor: pointer;
  }
}

.faq__item__answer {
  width: 80%;
  margin-top: 20px;
  font-family: 'Reserva-Sans-Regular';
  font-size: 14px;
  color: #8f8f8f;
}

@media (min-width: $screen-lg) {
  .faq {
    flex-direction: row;
    margin-top: 86px;
  }

  .faq__left {
    width: 40%;

    header {
      font-size: 48px;
      text-align: unset;
    }

    div {
      font-size: 18px;
      text-align: unset;
    }
  }

  .faq__right {
    width: 60%;
    font-size: 18px;
  }

  .faq__item {
    font-size: 18px;
  }

  .faq__item__answer {
    font-size: 16px;
  }
}
