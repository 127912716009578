@import '../../../../styles/variables.scss';

.hero__background {
  height: 400px;
  width: 100vw;
  position: absolute;
  background-size: cover;
  left: 0;
  z-index: 1;
  background-image: url('../../../../assets/icons/heroMobile.png');
  // background-position: 10%;
  // background-position: center;
}

.overlay {
  height: 400px;
  width: 100vw;
  position: absolute;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 1%, rgba(0, 0, 0, 0.5) 50%, black 100%);
  z-index: 2;
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  z-index: 3;

  img {
    width: 220px;
    height: auto;
  }
}

.hero__title {
  font-family: 'Reserva-Sans-Thin';
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 28px;

  span {
    &:first-of-type {
      font-family: 'Reserva-Sans-Thin';
      text-decoration: line-through;
    }
    font-family: 'Reserva-Sans-Bold';
  }

  h3 {
    // text-decoration: line-through;
  }
}

.hero__inputs {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-direction: column;
  width: 100%;
}

.hero__button {
  width: 100%;
}

.hero__advantages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  h1 {
    font-family: 'Reserva-Serif-Bold';
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  p {
    width: 100%;
    font-size: 12px;
    font-family: 'Reserva-Sans-Thin';
    text-align: center;
  }
}

.hero__advantages__itens {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-top: 36px;
  gap: 12px;
  z-index: 4;
  &::-webkit-scrollbar {
    display: none;
  }
}

.hero__advantages__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 85%;
  padding: 20px 4px;
  border: solid 1px transparent;
  border-radius: 14px;
  background-image: linear-gradient(#1c1c1c, #1c1c1c), $color-gradient-02;
  background-origin: border-box;
  background-clip: padding-box, border-box;

  img {
    height: auto;
    width: 50px !important;
  }
}

.hero__advantages__item__title {
  font-family: 'Reserva-Serif-Bold';
  font-size: 24px;
  margin-top: 12px;
  text-align: center;
}

.hero__advantages__item__description {
  text-align: center;
  font-family: 'Reserva-Sans-Thin';
  font-size: 14px;

  span {
    font-family: 'Reserva-Sans-Bold';
  }
}

@media (min-width: $screen-lg) {
  .hero__background {
    height: 680px;
    background-image: url('../../../../assets/icons/hero.png');
  }

  .overlay {
    height: 680px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 80%, black 100%);
  }

  .hero {
    margin-top: 200px;

    img {
      width: 290px;
    }
  }

  .hero__title {
    font-size: 20px;
    width: 50%;
    margin-top: 24px;
  }

  .hero__inputs {
    flex-direction: row;
    justify-content: center;

    input {
      width: 200px;
    }
  }

  .hero__button {
    width: 280px;
  }

  .hero__advantages {
    margin-top: 50px;
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 20px;
      width: 80%;
    }
  }

  .hero__advantages__itens {
    gap: 14px;
    justify-content: center;
  }

  .hero__advantages__item {
    width: 330px;
    min-width: unset;

    img {
      width: 60px !important;
    }
  }

  .hero__advantages__item__title {
    font-size: 32px;
  }

  .hero__advantages__item__description {
    font-size: 18px;
  }
}
