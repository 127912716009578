@import '../../styles/variables.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Inter';
  background: $color-gradient-01;
  border-radius: 50px;
  border: none;
  padding: 10px 30px;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  box-sizing: border-box;
  height: 36px;
  letter-spacing: 2px;
  // width: fit-content;
}

@media (min-width: $screen-lg) {
  .button {
    font-size: 12px;
  }
}
