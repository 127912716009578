@import '../../../../styles/variables.scss';

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  z-index: 2;

  footer {
    margin-top: 8px;
    text-align: center;
    font-family: 'Reserva-Sans-Regular';
    font-size: 12px;
    color: #7b7b7b;
  }

  h1 {
    font-family: 'Reserva-Sans-Regular';
    font-size: 10px;
    color: white;
    text-align: center;
    margin: 20px;
  }
}

.footer__background {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  background: rgba(20, 20, 20, 0.38);
  border-top: 1px solid #1b1b1b;
}

.footer__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    font-family: 'Reserva-Sans-Regular';
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 60px;
    height: auto;
  }
}

.footer__container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.footer__pages {
  display: flex;
  flex-direction: column;

  a {
    cursor: pointer;
  }

  span {
    width: 100%;
    border: 1px dashed #414141;
    margin: 10px 0;
  }
}

.footer__media {
  display: flex;
  gap: 30px;
  align-self: center;
  margin-bottom: 38px;

  img {
    cursor: pointer;
    height: 20px !important;
    width: 20px !important;
  }
}

.footer__address {
  margin-top: 18px;
  text-align: center;
  font-family: 'Reserva-Sans-Regular';
  font-size: 14px;
}

.footer__rights {
  text-align: center;
  font-family: 'Reserva-Sans-Thin';
  font-size: 10px;
  margin: 30px 0;
}

@media (min-width: $screen-lg) {
  .footer__main {
    flex-direction: row;

    img {
      height: auto;
      width: 50px;
    }

    h1 {
      display: none;
    }
  }

  .footer__pages {
    display: flex;
    flex-direction: row;
    gap: 34px;
  }

  .footer__container {
    flex-direction: row;
    align-items: center;
  }

  .footer__media {
    margin-left: auto;
    margin-bottom: 0;
  }

  .footer__pages {
    margin-left: 200px;
    gap: 34px;

    span {
      display: none;
    }
  }

  .footer__address {
    margin-top: 28px;
  }

  .footer__rights {
    display: none;
  }
}
