@import '../../../../styles/variables.scss';

.sign {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 32px;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    height: auto;
  }
}

.sign__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Reserva-Serif-Thin';
  font-size: 12px;

  text-align: center;

  header {
    font-family: 'Reserva-Serif-Bold';
    font-size: 30px;
  }

  p {
    font-family: 'Reserva-Sans-Thin';
    margin-bottom: 26px;
  }

  span {
    font-family: 'Reserva-Sans-Bold';
  }
}

.sign__footer__mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    width: 100%;
  }

  footer {
    font-family: 'Reserva-Sans-Thin';
    font-size: 14px;
    margin-top: 26px;
  }
}

.sign__footer__desktop {
  display: none;
}

@media (min-width: $screen-lg) {
  .sign {
    flex-direction: row;
    margin-top: 90px;
    img {
      width: auto;
      height: 560px;
    }
  }

  .sign__info {
    font-size: 18px;
    text-align: unset;
    margin-left: 50px;

    header {
      font-size: 48px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  .sign__footer__mobile {
    display: none;
  }

  .sign__footer__desktop {
    display: flex;
    flex-direction: column;

    footer {
      font-family: 'Reserva-Sans-Thin';
      font-size: 18px;
      margin-top: 20px;
    }

    span {
      width: 150px;
    }
  }
}
