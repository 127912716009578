@import '../../../../styles/variables.scss';

.alert {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 14px;
  background-image: linear-gradient(#1c1c1c, #1c1c1c), $color-gradient-02;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 20px;
  margin-top: 36px;

  div {
    font-family: 'Reserva-Sans-Thin';
    font-size: 12px;

    span {
      font-family: 'Reserva-Sans-Bold';
    }

    img {
      display: unset;
      width: 24px;
      height: 20px;
      margin-right: 10px;
    }
  }

  img {
    display: none;
    width: 56px;
    height: 46px;
    margin-right: 20px;
  }

  .glow {
    background: radial-gradient(rgba(196, 169, 104, 0.2), rgba(0, 0, 0, 0.05) 70%);
    left: 0;
  }
}

@media (min-width: $screen-lg) {
  .alert {
    padding: 40px 80px;
    margin-top: 86px;
    div {
      font-size: 20px;

      img {
        display: none;
      }
    }

    img {
      display: unset;
      width: 60px;
      height: 50px;
      margin-right: 36px;
    }
  }
}
