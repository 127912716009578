@import '../../../../styles/variables.scss';

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #000000;
  width: 100%;
  padding: 20px 0px;
  z-index: 3;
}

.header__right {
  display: none;
  align-items: center;

  span {
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    margin-right: 30px;
    cursor: pointer;
  }

  img {
    cursor: pointer;
    margin-left: 30px;
  }
}

@media (min-width: $screen-lg) {
  .header {
    justify-content: space-between;
  }
  .header__right {
    display: flex;
  }
}
