@import '../../styles/variables.scss';

.input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Inter';
  font-size: 11px;
  color: white;

  input {
    // position: relative;
    // border: 1px solid transparent;
    // background-image: $color-gradient-02;
    // background-origin: border-box;
    // background-clip: content-box, border-box;
    // border-image-source: $color-gradient-02;
    // border-width: 1px;
    // border-image-slice: 1;
    border: solid 1px transparent;
    border-radius: 4px;
    background-image: linear-gradient(#1c1c1c, #1c1c1c), $color-gradient-02;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    color: white;
    font-family: 'Inter';
    font-size: 11px;
    // border-radius: 4px;
    // background-color: $color-black-light;
    outline: none;
    padding: 12px;
    font-size: 12px;
  }
}
