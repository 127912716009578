@import '../../../../styles/variables.scss';

.more-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 46px;

  header {
    font-family: 'Reserva-Serif-Bold';
    font-size: 30px;
    text-align: center;
  }

  p {
    font-family: 'Reserva-Sans-Thin';
    font-size: 12px;
    text-align: center;
    margin-bottom: 24px;
    span {
      font-family: 'Reserva-Sans-Bold';
    }
  }
}

.more-info__button {
  width: 100%;
}

@media (min-width: $screen-lg) {
  .more-info {
    margin-top: 86px;
    margin-bottom: 60px;
    header {
      font-size: 48px;
      text-align: unset;
    }

    p {
      font-size: 20px;
      width: 80%;
    }
  }

  .more-info__button {
    width: 150px;
  }
}
