@import '../../styles/variables.scss';

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;
}

.home__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}

@media (min-width: $screen-lg) {
  .home {
    padding: 0px 100px;
  }
}
