// Neutral

// Primary
$color-black-light: rgba(26, 25, 25, 0.8);
// Gradients
$color-gradient-01: linear-gradient(90deg, #c4a968 0%, rgba(196, 169, 104, 0.15) 100%);

$color-gradient-02: linear-gradient(45deg, #c4a968 0%, black 30%, #c4a968 60%, black 100%);

// Secondary

// Warning

// Error

// Fonts

// Screens
$screen-xs: 360px;
$screen-sm: 480px;
$screen-md: 720px;
$screen-lg: 960px;
$screen-xl: 1140px;
